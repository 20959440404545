import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { NFormAPIJsonCustomFormDataContent } from 'src/app/api-services/portal/nform-nonuser.interface';

@Component({
	selector: 'nform-picture-img-grid',
	templateUrl: './img-grid.component.html',
	styleUrls: ['./img-grid.component.scss'],
	imports: [CommonModule, MatRippleModule]
})
export class NFormPictureImgGridComponent implements OnInit {

	rippleColor = 'var(--color-palette-primary-A700, rgba(0,0,0))';
	noGrid = false;
	rows = [];
	columns = [];
	keyString: string;

	@Input() formlyFieldDataContent: NFormAPIJsonCustomFormDataContent;
	@Input() formlyFieldDataKey: string | number | (string | number)[];
	@Input() formlyFieldDataModel: any;
	@Input() formlyFieldDataFormControl: AbstractControl<any, any>;
	@Input() formlyFieldGridStateIsDisabled: boolean = false;
	@Input() openFullscreen = false;

	ngOnInit(): void {
		this.setGrid();
		this.setColor();
		this.initModelValue();
	}

	initModelValue(){
		this.keyString = this.formlyFieldDataKey as string;
		if(!this.noGrid && !this.formlyFieldDataModel[this.keyString]){
			this.formlyFieldDataModel[this.keyString] = [];
		}
	}

	toggleCell(x, y){

		if(
			this.openFullscreen
			||
			this.formlyFieldDataFormControl?.disabled
			||
			this.formlyFieldGridStateIsDisabled
		){
			return;
		}

		const value = this.getMatrixPositionFormat(x, y);
		const indexOf = this.formlyFieldDataModel[this.keyString].indexOf(value);

		if(indexOf === -1){
			this.formlyFieldDataModel[this.keyString].push(value);
		}else{
			this.formlyFieldDataModel[this.keyString].splice(indexOf, 1);
		}

		this.formlyFieldDataFormControl.setValue(this.formlyFieldDataModel[this.keyString]);
	}

	cellIsEneabled(x, y){
		const value = this.getMatrixPositionFormat(x, y);
		const indexOf = this.formlyFieldDataModel[this.keyString].indexOf(value);
		return indexOf >= 0;
	}

	setGrid(){

		this.rows = [].constructor(parseInt(this.formlyFieldDataContent.lines, 10));
		this.columns = [].constructor(parseInt(this.formlyFieldDataContent.columns, 10));

		if(!this.rows.length && !this.columns.length){
			this.noGrid = true;
		}

	}

	setColor(){
		if(this.formlyFieldDataContent.color){
			this.rippleColor = this.formlyFieldDataContent.color;
		}
	}

	private getMatrixPositionFormat(x, y){
		return `${x+1},${y+1}`;
	}
}
